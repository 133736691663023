<template>
	<div class="px-4 py-2">
		<strong>Calendar Appointment Link:</strong>
		<div class="d-flex align-center">
			<v-text-field
				:value="calendarLink"
				:placeholder="placeholder"
				readonly
				hide-details
				class="url-input"
			/>
			<v-btn
				dark small fab depressed color="q_green_1"
				class="url-input-edit ml-4"
				@click="showDialog"
			>
				<v-icon>icon-edit-q</v-icon>
			</v-btn>
		</div>

		<v-dialog v-if="doShowDialog" v-model="doShowDialog" :persistent="loading" width="95%" max-width="800px">
			<v-card :loading="loading">
				<v-card-title>
					<strong><p v-html="dialogTitle" /></strong>
				</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="link"
						:placeholder="formPlaceholder"
						:rules="[
							(value) => !!value || requiredMessage,
							() => isLinkValid || invalidLinkMessage,
							() => isApprovedCalendarServices || invalidServiceLinkMessage,
						]"
						class="url-input"
					/>
					<v-alert class="mt-4 mb-0" type="info" text>
						<div><strong>Approved calendar services:</strong></div>
						<div>
							<ul v-for="service in approvedCalendarServices" :key=service class="pl-0 my-1">
								- {{ service }}
							</ul>
						</div>
					</v-alert>
				</v-card-text>
				<v-card-actions class="d-flex justify-end pb-4 px-6">
					<v-btn depressed @click="doShowDialog = false">Cancel</v-btn>
					<v-btn
						dark depressed color="q_green_1"
						:disabled="!allowSave"
						class="url-input-save ml-4"
						@click="handleSave"
					>
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import User from '@/store/Models/User'

const pattern = new RegExp('^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

const approvedCalendarServices = [
	'calendly.com',
	'acuityscheduling.com',
	'cal.com',
	'calendar.google.com',
	'go.oncehub.com',
	'go.scheduleyou.in',
	'levelup-crm.com',
	'me.links.insure',
	'me.links-insure.com',
	'picktime.com',
]

export default {
	props: {
		calendarLink: { type: String },
	},
	data () {
		return {
			link: '',
			doShowDialog: false,
			loading: false,
			placeholder: 'Click the pencil icon to input calendar link',
			formPlaceholder: 'Input calendar link',
			requiredMessage: 'Field is required',
			invalidLinkMessage: 'This does not appear to be a valid URL',
			invalidServiceLinkMessage: 'Please enter a valid URL from an approved calendar service',
			approvedCalendarServices,
		}
	},
	computed: {
		trimmedLink () {
			return this.link?.trim?.() || ''
		},
		isLinkValid () {
			if (!this.trimmedLink) { return true }
			return this.trimmedLink && pattern.test(this.trimmedLink)
		},
		isApprovedCalendarServices () {
			if (!this.isLinkValid) { return false }
			if (!this.trimmedLink) { return false }
			const protocol = new URL(this.trimmedLink).protocol
			return approvedCalendarServices.some((service) => {
				return this.trimmedLink.startsWith(`${protocol}//${service}`)
			})
		},
		hasChanges () {
			if (!this.trimmedLink && !this.calendarLink) { return false }
			return this.trimmedLink !== this.calendarLink
		},
		allowSave () {
			return this.trimmedLink &&
				this.isLinkValid &&
				this.isApprovedCalendarServices &&
				this.hasChanges &&
				!this.loading
		},
		dialogTitle () {
			return this.calendarLink
				? 'Edit your Calendar Appointment Link'
				: 'Enter your Calendar Appointment Link'
		},
	},
	watch: {
		doShowDialog (val) {
			if (!val) {
				this.link = ''
			}
		},
	},
	methods: {
		showDialog () {
			this.link = this.calendarLink
			this.doShowDialog = true
		},
		async handleSave () {
			if (!this.isLinkValid) { return }
			if (!this.isApprovedCalendarServices) { return }
			this.loading = true
			try {
				const res = await QuilityAPI.storeUserAppointmentCalendar(this.trimmedLink)
				if (res.error) {
					const errorMessages = Object.values(res.rawError?.error || {}).join('<br>')
					this.showError('There was a problem saving the Calendar URL.<br>', errorMessages)
				} else {
					this.updateUserStore({ AppointmentCalendarURL: this.trimmedLink })
					this.showSuccess('Calendar Appointment Link saved successfully.')
					this.doShowDialog = false
				}
			} catch (err) {
				this.showError('Could not save changes.<br>', err.message)
			} finally {
				this.loading = false
			}
		},
		updateUserStore (data) {
			const user = { ...this.user, ...data }
			User.create({ data: user })
		},
	},
}
</script>

<style lang="scss" scoped>
.url-input {
	margin-top: 0;
	padding-top: 0;
	flex: 1 1 auto;
}

.url-input-edit {
	border-radius: 50%;
}

.url-input-save {
	&.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
		color: #00000042 !important;
		background-color: #0000001f !important;
	}
}
</style>